<script setup lang="ts">
const runtimeConfig = useRuntimeConfig()
const isProd = runtimeConfig.public.env === 'prod'

const props = defineProps<{
	data: {
		id: string,
		pollId: string,
		clientId: string,
		contentType: string,
		title: string,
		sponsor: {
			imageUrl: string,
			text: string,
		},
	},
}>()

const clientId = props.data.clientId || runtimeConfig.public.clientId
</script>

<template>
	<div
		class="rounded-lg shadow-md h-full"
		:class="{'max-w-md mb-4 mx-auto': data.contentType === 'POLL'}"
	>
		<incrowd-poll
			:id="data.pollId"
			:client-id="clientId"
			:access-token-cookie-name="isProd ? 'PRL_sso_at' : 'PRL_sso_at_stage'"
		/>
	</div>
</template>
