<script setup lang="ts">
const runtimeConfig = useRuntimeConfig()
const isProd = runtimeConfig.public.env === 'prod'
defineProps<{
	data: {
		formId: string,
	},
}>()
const clientId = runtimeConfig.public.clientId
</script>
<template>
	<incrowd-forms
		:id="data.formId"
		:client-id="clientId"
		:access-token-cookie-name="isProd ? 'PRL_sso_at' : 'PRL_sso_at_stage'"
	/>
</template>
